<template>
    <a :href="redirectUrl" @click.prevent="openUrl(redirectUrl)">
        <img v-if="organizationDetails && organizationDetails.logo_url" :src="organizationDetails.logo_url" :alt="`${organizationDetails.name} logo`" />
        <img v-else :src="Logo" alt="Valence logo" />
    </a>
</template>

<script setup>
import Logo from "~img/logo.png";
import { isInIFrame, openUrl } from "~vue/utils";
import { computed, inject } from "vue";

const organizationDetails = inject("organization_details");

const { url } = defineProps({
    url: { type: String, default: "/" },
});
const redirectUrl = computed(() => {
    return isInIFrame() ? `${url}?client=teams` : `${url}`;
});
</script>
